import React, { useState, useEffect } from "react";
import Config from "../../Config";
import PropTypes from "prop-types";
import {
  CognitoUserPool,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";
import { connect } from "react-redux";
import { useTranslation, withTranslation } from "react-i18next";

import RegistrationForm from "./components/RegistrationForm/RegistrationForm";

import { TAXNUMBER_STATUS } from "LixaniAPI/enums";
import {
  setEmail,
  setPhone,
  setIsPhoneAccount,
} from "../../store/actions/user";
import { setRoute, ROUTE } from "../../store/actions/router";

import { passwordHelper } from "util/password";
import { password2Helper } from "util/password2";

const initialDateOfBirth = () => {
  let d = new Date();
  const currentYear = d.getFullYear();
  d.setFullYear(currentYear - 14);
  d.setMonth(0);
  d.setDate(1);
  return d.toISOString().slice(0, 10);
};

const maxDateOfBirth = () => {
  let d = new Date();
  const currentYear = d.getFullYear();
  d.setFullYear(currentYear - 13);
  d.setMonth(-1);
  d.setDate(-1);
  return d.toISOString().slice(0, 10);
};

const minDateOfBirth = () => {
  let d = new Date();
  const currentYear = d.getFullYear();
  d.setFullYear(currentYear - 120);
  return d.toISOString().slice(0, 10);
};

const initRegistrationData = {
  business_id: { value: "", required: true, error: false, type: "business_id" },
  city: { value: "", required: false, error: false },
  co: { value: "", required: false, error: false },
  contact_first_name: { value: "", required: false, error: false },
  contact_last_name: { value: "", required: false, error: false },
  contact_street1: { value: "", required: false, error: false },
  contact_street2: { value: "", required: false, error: false },
  contact_co: { value: "", required: false, error: false },
  contact_postcode: { value: "", required: false, error: false },
  contact_city: { value: "", required: false, error: false },
  contact_country: { value: "", required: false, error: false },
  contact_email: { value: "", required: false, error: false },
  contact_phone: { value: "", required: false, error: false },
  country: { value: "", required: false, error: false },
  dateOfBirth: {
    value: initialDateOfBirth(),
    required: true,
    error: false,
    type: "date",
    max: maxDateOfBirth(),
    min: minDateOfBirth(),
  },
  email: { value: "", required: false, error: false, type: "email" },
  explanation: { value: "", required: false, error: false },
  first_name: { value: "", required: true, error: false },
  last_name: { value: "", required: true, error: false },
  middle_name: { value: "", required: false, error: false },
  name: { value: "", required: true, error: false },
  password: {
    value: "",
    required: true,
    error: false,
    type: "password",
    check_same: "password2",
  },
  password2: {
    value: "",
    required: true,
    error: false,
    type: "password",
    is_same: "password",
  },
  phone: { value: "", required: true, error: false, type: "phone" },
  postcode: { value: "", required: false, error: false },
  prefill: { value: false, required: false, error: false },
  street1: { value: "", required: false, error: false },
  street2: { value: "", required: false, error: false },
  taxNumber: { value: "", required: true, error: false },
};

function RegistrationLogic(props) {
  const [cognitoUser, setCognitoUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [stateError, setError] = useState(null);
  const [registered, setRegistered] = useState(false);
  const [registrationData, setRegistrationData] = useState({
    ...initRegistrationData,
  });
  const [taxNumberCheckedAt, setTaxNumberCheckedAt] = useState("");
  const [taxNumberStatus, setTaxNumberStatus] = useState(
    TAXNUMBER_STATUS.UNCHECKED
  );
  const [userConfirmed, setUserConfirmed] = useState(false);
  const [businessIdCountry, setBusinessIdCountry] = useState("finland");
  const [dashAlreadyAdded, setDashAlreadyAdded] = useState(false);
  const [handleBossCheckboxValue, setBossValue] = useState("");
  const { t, i18n } = useTranslation("common");

  useEffect(() => {
    const initPhone = () => {
      let prefill = "";

      // callback if not signed in.
      try {
        prefill = decodeURIComponent(
          new URL(window.location.href).search.split("?callback=")
        ).split("?data=");
      } catch (err) {
        prefill = new URL(window.location.href).search.split("?data=");
      }

      let prefilldata = {};
      if (prefill.length > 1) {
        try {
          let d = JSON.parse(atob(prefill[1]));
          prefilldata = Object.keys(d).reduce((resp, key) => {
            resp[key] = { ...initRegistrationData[key], value: d[key] };
            return resp;
          }, {});
          prefilldata["prefill"] = {
            value: d.prefill ? d.prefill.value : true,
            required: false,
            error: false,
          };
        } catch (err) {
          console.log(err);
        }
      }
      // if(){

      //}
      if (!props.phone && prefilldata["phone"]) {
        props.onSetPhone(prefilldata["phone"].value);
      }

      return prefilldata["phone"] || "";
    };

    initPhone();
    /*setRegistrationData({
          ...registrationData,
          boss: { ...handleBossCheckboxValue },
        })
        */
  }, []);

  const register = async () => {
    setError(null);
    setLoading(true);
    let test = { ...registrationData["boss"] };

    console.log(test, "registerAsync sisällä handlebossvalue");
    /*setRegistrationData({
      ...registrationData,
      boss: { ...handleBossCheckboxValue },
    })
    */
    let userPool = new CognitoUserPool({
      UserPoolId: Config.COGNITO_USER_POOL_ID,
      ClientId: Config.COGNITO_CLIENT_ID,
    });

    const data = { ...registrationData };
    const email = props.email.toLowerCase();
    const phone = props.phone.replace(/[^a-zA-Z0-9+ ]/g, "");
    //data.phone.value; //props.phone;
    const username = props.isPhoneAccount ? phone : email;

    let attributeList = [];
    attributeList.push(
      new CognitoUserAttribute({ Name: "email", Value: email })
    );
    attributeList.push(
      new CognitoUserAttribute({
        Name: "phone_number",
        Value: data.phone.value.replace(/[^a-zA-Z0-9+ ]/g, ""),
      })
    );
    attributeList.push(
      new CognitoUserAttribute({
        Name: "given_name",
        Value: data.first_name.value,
      })
    );
    attributeList.push(
      new CognitoUserAttribute({
        Name: "family_name",
        Value: data.last_name.value,
      })
    );

    //    if (data.middle_name.value) {
    //      attributeList.push(new CognitoUserAttribute({ Name: 'middle_name', Value: data.middle_name.value }));
    //    }

    const reddata = Object.keys(data)
      .filter(
        (key) =>
          ![
            "project",
            "password",
            "password2",
            "email",
            "phone",
            "first_name",
            "last_name",
            "middle_name",
            "boss",
          ].includes(key)
      )
      .reduce((resp, key) => {
        const ob = data[key];
        if (ob.value) {
          resp[key] = ob.value;
        }
        return resp;
        //			({[key]:data[key].value})
      }, {});
    if (handleBossCheckboxValue !== "") {
      reddata["boss"] = handleBossCheckboxValue;
    }
    //-- Add taxNumber check date and status
    if (taxNumberCheckedAt) {
      reddata["taxNumberCheckedAt"] = taxNumberCheckedAt;
      reddata["taxNumberStatus"] = taxNumberStatus;
    }

    attributeList.push(
      new CognitoUserAttribute({
        Name: "custom:employee_base64",
        Value: btoa(JSON.stringify(reddata)),
      })
    );

    //-- Add locale attribute
    let locale = i18n.language;
    if (!locale) {
      locale = navigator.language || navigator.userLanguage;
    }
    locale = locale.split("-")[0];
    attributeList.push(
      new CognitoUserAttribute({ Name: "locale", Value: locale })
    );

    let result = null;
    try {
      result = await new Promise((resolve, reject) => {
        userPool.signUp(
          username,
          data.password.value,
          attributeList,
          null,
          (error, result) => {
            if (error) reject(error);
            else resolve(result);
          }
        );
      });
    } catch (error) {
      setLoading(false);
      console.error("Registration error", JSON.stringify(error));
      if (error.code === "UsernameExistsException") {
        setError(props.t("error.already_registered"));
      } else if (error.message === "Invalid phone number format.") {
        setError(props.t("error.invalid_phone_format"));
      } else {
        setError(error.message);
      }
      return;
    }

    setCognitoUser(result.user);
    setRegistered(true);
    setRegistrationData({ ...initRegistrationData });
    setUserConfirmed(result.userConfirmed);
  };

  const handleChangeData = (name) => (event) => {
    let data = { ...registrationData[name] };
    let value = event;
    try {
      value = event.target.value;
    } catch (err) {
      //console.log(err);
    }

    if (name === "phone") {
      value = value.replace(/ /g, "");
    }
    data.value = value;

    if (data.type === "email") {
      props.onSetEmail(value); //event.target.value);
    } else if (data.type === "phone") {
      props.onSetPhone(value); //event.target.value);
      //props.onSetPhone(event);
    }
    /*
    try {
      data.value = event.target.value;
    } catch (error) {
      data.value=event
    }
    */

    data.error = !dataValid(data)(true);

    setRegistrationData({
      ...registrationData,
      [name]: data,
    });
  };

  const dataValid = (data) => (check) => {
    let value = data.value;
    if (data.type === "date") {
      const dateValue = new Date(value);
      let compareDate = new Date();
      const currentYear = compareDate.getFullYear();
      compareDate.setFullYear(currentYear - 13);
      compareDate.setMonth(-1);
      compareDate.setDate(-1);

      if (dateValue > compareDate) {
        return false;
      }
    }

    if (data.required && value.trim() === "") {
      return false;
    }

    if (data.is_same) {
      if (
        password2Helper(props.t)(
          registrationData[data.is_same].value,
          value
        ) !== null
      )
        return false;
    } else {
      if (data.type === "password" && passwordHelper(props.t)(value) !== null) {
        return false;
      } else if (check && data.type === "business_id") {
        console.log("BUSINESS ID CHECK");
        // check y-tunnus
        let ytunnus = "0000000" + data.value.match(/\d/g).join("");
        ytunnus = ytunnus.substr(ytunnus.length - 8, 8).split("");
        let cr1 = parseInt(ytunnus.pop());

        const c = ytunnus.reduce(
          (resp, ch) => {
            const i = parseInt(ch);
            const k = resp.shift();
            resp[resp.length - 1] += i * k;
            return resp;
          },
          [7, 9, 10, 5, 8, 4, 2, 0]
        );
        const cr2 = c % 11 === 0 ? 0 : 11 - (c % 11);

        // y-tunnus ok. check sv
        if (cr1 === cr2) {
          const url =
            "https://selvitysvelvollisuus.fi:9000/svapi/company-info/";
          //	const url='https://dev.selvitysvelvollisuus.fi:9000/svapi/company-info/';
          fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ business_id: data.value }),
          })
            .then((res) => res.json())
            .then((x) => {
              console.log("löytyi", x);
              const d = { ...registrationData["name"] };
              d.value = x.trade_name;

              setRegistrationData({
                ...registrationData,
                ["name"]: d || "",
              });
            })
            .catch((res) => {
              console.log(res);
            });
        }
      }
    }
    return true;
  };

  const handleBusinessIdCountryRadioChange = (e) => {
    setBusinessIdCountry(e.target.value);
  };
  /*useEffect(() =>
 {
     setRegistrationData({
        ...registrationData,
        boss:{handleBossCheckboxValue}
        //boss: { ...businessIdData.value },
      })
  }) */
  const handleBossCheckbox = (e) => {
    let businessIdData = { ...registrationData["business_id"] };

    if (e.target.checked === true) {
      setBossValue(businessIdData.value);
      console.log("true", handleBossCheckboxValue);
    }
    if (e.target.checked === false) {
      setBossValue("");
      console.log("false", handleBossCheckboxValue);
    }
  };
  /*   if(handleBossCheckboxValue != '') {
     setRegistrationData({
       ...registrationData,
       boss: { ...handleBossCheckboxValue },
     })
   }*/
  //console.log(e.target.checked,"handleBossCheckbox")
  //setBossValue(e.target.value);
  //console.log(handleBossCheckboxValue,"handlebossCheckboxvalue")

  /*useEffect(() => {
      setRegistrationData({
      ...registrationData,
      boss: { ...handleBossCheckboxValue },
    })
    console.log("handlebosscheckbox",handleBossCheckboxValue)
    })
    */
  useEffect(() => {
    if (handleBossCheckboxValue !== "") {
      console.log(handleBossCheckboxValue, "Setregisteriintämä");
      setRegistrationData({
        ...registrationData,
        boss: { handleBossCheckboxValue },
      });
    }
  }, [handleBossCheckboxValue]);
  const handleChangeBusinessId = (event) => {
    let businessIdData = { ...registrationData["business_id"] };
    let nameData = { ...registrationData["name"] };
    let businessIdValue = event;
    try {
      businessIdValue = event.target.value;
      if (businessIdCountry === "finland") {
        businessIdValue = businessIdValue.replace(/[^0-9-]/g, '');
      }
      // if (businessIdValue.slice(-1) === "+") {
      //   businessIdValue = businessIdValue.substring(
      //     0,
      //     businessIdValue.length - 2
      //   );
      // } else if (
      //   businessIdData.value.includes("-") &&
      //   businessIdData.value.length > businessIdValue.length
      // ) {
      //   businessIdValue = businessIdValue.substring(
      //     0,
      //     businessIdValue.length - 1
      //   );
      // }
    } catch (err) {
      //console.log(err);
    }

    //-- prevent non-numeric values
    //if (isNaN(businessIdValue)) return;'

    if (businessIdValue.length > 9 && businessIdCountry === "finland") {
      return;
    }

    businessIdData.value = businessIdValue;

    if (businessIdCountry === "finland") {
      if (businessIdValue.length === 7 && !dashAlreadyAdded) {
        businessIdData.value = businessIdValue + "-";
        setDashAlreadyAdded(true);
      }
      if (businessIdValue.length === 7 && dashAlreadyAdded) {
        businessIdData.value = businessIdValue + "-";
        setDashAlreadyAdded(false);
      }

      if (dashAlreadyAdded && businessIdValue.length === 6) {
        setDashAlreadyAdded(false);
      }
      if (businessIdValue.length === 9) {
        if (businessIdValue.charAt(businessIdValue.length - 1) === "-") {
          businessIdValue = businessIdValue.slice(-1);
        }
        //businessIdValue = businessIdValue.replace('-','');
        // check y-tunnus
        let businessId;
        try {
          businessId = "0000000" + businessIdValue.match(/\d/g).join("");
        } catch (err) {
          return;
        }
        businessId = businessId.substr(businessId.length - 8, 8).split("");
        let cr1 = parseInt(businessId.pop());

        const c = businessId.reduce(
          (resp, ch) => {
            const i = parseInt(ch);
            const k = resp.shift();
            resp[resp.length - 1] += i * k;
            return resp;
          },
          [7, 9, 10, 5, 8, 4, 2, 0]
        );
        const cr2 = c % 11 === 0 ? 0 : 11 - (c % 11);
        // y-tunnus ok. check sv
        if (cr1 === cr2) {
          const url = `https://avoindata.prh.fi/opendata-ytj-api/v3/companies?businessId=${businessIdValue}`;
          console.log("url", url);
          //curl -X GET --header 'Accept: application/json' 'https://avoindata.prh.fi/opendata-ytj-api/v3/companies?businessId=${businessIdValue}'
          // fetch using the above curl command
          fetch(url, {
            headers: {
              Accept: "application/json",
            },
          })
            .then((res) => res.json())
            .then((x) => {
              console.log("x", x);
              if (x.companies.length > 0 && x.companies[0].names && x.companies[0].names[0].name) {
                nameData.value = x.companies[0].names[0].name;
                setRegistrationData({
                  ...registrationData,
                  business_id: { ...businessIdData },
                  name: { ...nameData },
                });
              } else {
                // console.log("not found from avoin data tr, look up from bis");
                console.log("not found from avoin data");
                // fetchFromAvoinDataBis();
              }
            })
            .catch((res) => {
              console.log(res);
            });
        }
      }
    }

    const fetchFromAvoinDataBis = () => {
      const url = `https://avoindata.prh.fi/bis/v1/${businessIdValue}`;

      fetch(url, {
        headers: {
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((x) => {
          if (x.results.length > 0 && x.results[0].name) {
            nameData.value = x.results[0].name;
            setRegistrationData({
              ...registrationData,
              business_id: { ...businessIdData },
              name: { ...nameData },
            });
          } else {
            // fetchFromFinder();
            console.log("business not found");
          }
        })
        .catch((res) => {
          console.log(res);
        });
    };

    // const fetchFromFinder = () => {
    //   const url = `https://www.finder.fi/search?what=${businessIdValue}`;

    //   fetch(url, {
    //     headers: {
    //       Accept: "application/json",
    //     },
    //   })
    //     .then((res) => res.json())
    //     .then((x) => {
    //       if (x.results.length > 0 && x.results[0].name) {
    //         nameData.value = x.results[0].name;
    //         setRegistrationData({
    //           ...registrationData,
    //           business_id: { ...businessIdData },
    //           name: { ...nameData },
    //         });
    //       } else {
    //         console.log("business not found");
    //       }
    //     })
    //     .catch((res) => {
    //       console.log(res);
    //     });
    // };

    console.log(nameData);

    setRegistrationData({
      ...registrationData,
      business_id: { ...businessIdData },
      name: { ...nameData },
    });
  };

  const lambdaCheckTaxNumber = async () => {
    let taxNumberError = false;
    let taxNumberChecked = false;
    let taxNumberErrorMessage = "";
    setRegistrationData({
      ...registrationData,
      taxNumber: { ...registrationData.taxNumber, error: taxNumberError },
    });
    const dateOfBirth = registrationData.dateOfBirth.value;
    const taxNumber = registrationData.taxNumber.value;
    if (taxNumber.length === 0) return;
    const variables = { taxNumber, dateOfBirth };

    const url = Config.CHECKTAXNUMBER_URL;
    const apikey = Config.CHECKTAXNUMBER_APIKEY;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": apikey,
      },
      body: JSON.stringify(variables),
    })
      .then((res) => res.json())
      .then((x) => {
        const statusCode = x[0];
        const message = JSON.parse(x[1]);
        console.log(statusCode);
        console.log(message);
        if (statusCode === 200) {
          if (message.InfoExists === false) {
            taxNumberError = true;
            taxNumberErrorMessage = t("auth.tax_number_error_or_inactive");
          } else if (message.TaxNumberMatchBirthday === false) {
            taxNumberError = true;
            taxNumberErrorMessage = t("auth.tax_number_birthday_mismatch");
          }
          taxNumberChecked = true;
        } else if (statusCode === 400) {
          taxNumberError = true;
          taxNumberChecked = true;
          if (message.ErrorCode === "1005") {
            taxNumberErrorMessage = t("auth.tax_number_too_short");
          }
        } else if (statusCode === 404 || statusCode === 500) {
          if (message.ErrorCode) {
            console.log(message.ErrorCode);
            console.log(message.ErrorText);
          }
        }
        if (taxNumberChecked) {
          console.log("taxNumberChecked", taxNumberChecked);
          setTaxNumberCheckedAt(new Date().toISOString());
          setTaxNumberStatus(
            taxNumberError ? TAXNUMBER_STATUS.INVALID : TAXNUMBER_STATUS.VALID
          );
        }
        setRegistrationData({
          ...registrationData,
          taxNumber: {
            ...registrationData.taxNumber,
            error: taxNumberError,
            errorMessage: taxNumberErrorMessage,
          },
        });
      })
      .catch((res) => console.log(res));
  };

  const togglePhoneAccount = () => {
    const isPhoneAccount = !props.isPhoneAccount;
    setRegistrationData({
      ...registrationData,
      email: { ...registrationData.email, required: !isPhoneAccount },
      phone: { ...registrationData.phone, required: isPhoneAccount },
    });

    props.onSetIsPhoneAccount(isPhoneAccount);
  };

  let content = null;
  let formValid = Object.entries(registrationData).every((d) =>
    dataValid(d[1])(false)
  );

  if (!registered) {
    content = (
      <RegistrationForm
        data={registrationData}
        email={props.email}
        error={stateError}
        isPhoneAccount={props.isPhoneAccount}
        isValid={dataValid(true)}
        loading={loading}
        phone={props.phone}
        onBlurTaxNumber={lambdaCheckTaxNumber}
        onChangeData={handleChangeData}
        onChangeBusinessId={handleChangeBusinessId}
        onChangeEmail={props.onSetEmail}
        onChangePhone={props.onSetPhone}
        passwordHelper={passwordHelper(props.t)}
        password2Helper={password2Helper(props.t)}
        register={register}
        submitEnabled={formValid}
        togglePhoneAccount={togglePhoneAccount}
        businessIdCountry={businessIdCountry}
        handleBusinessIdCountryRadioChange={handleBusinessIdCountryRadioChange}
        handleBossCheckbox={handleBossCheckbox}
      //handleBossCheckboxValue={handleBossCheckboxValue}
      />
    );
  } else if (userConfirmed) {
    content = <h3>Registration successful, {cognitoUser.getUsername()}</h3>;
  } else {
    props.setVerificationView();
  }

  return content;
}

RegistrationLogic.propTypes = {
  t: PropTypes.func.isRequired,
  setVerificationView: PropTypes.func.isRequired,
  onSetEmail: PropTypes.func.isRequired,
  onSetPhone: PropTypes.func.isRequired,
  onSetIsPhoneAccount: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  isPhoneAccount: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    setVerificationView: PropTypes.func.isRequired,
    email: state.user.email,
    phone: state.user.phone,
    isPhoneAccount: state.user.isPhoneAccount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setVerificationView: () => dispatch(setRoute(ROUTE.VERIFICATION)),
    onSetEmail: (email) => dispatch(setEmail(email)),
    onSetPhone: (phone) => dispatch(setPhone(phone)),
    onSetIsPhoneAccount: (isPhoneAccount) =>
      dispatch(setIsPhoneAccount(isPhoneAccount)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(RegistrationLogic));
export { RegistrationLogic };
